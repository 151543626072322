import React from "react";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// import About from "./pages/About";
// import Blog from "./pages/Blog/Blog";
// import Nav from "./components/Nav/Nav";
// import Home from "./pages/Home/Home";

import "./App.scss";
import styles from "./tempstyles.module.scss";
// import "./scss/reset.scss";

function App() {
  return (
    <div className={styles.background}>
      <div className={styles.card}>
        <h1 className={styles.heading}>The Bunny Guru</h1>
        <p className={styles.message}>
          Hey, sorry for the dust, we've got some construction going on. The
          site ran into some trouble and we're getting things back on track.
          We're hoping to have the site back up and running soon.
        </p>
        <br />
        <p className={styles.message}>
          Also, we're making some changes to the site name. We'll be moving to
          Fuzzy Bunny Cafe instead of the bunny guru because we like the way it
          sounds better. You'll still be able to get to the site by coming to
          thebunnyguru.com though, so don't worry we're not going away. I can't
          wait to show off the site once we get back.
        </p>
        <br />
        <p> Binky on!</p>
      </div>
    </div>
  );
}

export default App;
